const actionNamePrefix = 'MODAL';

export const SHOW = `${actionNamePrefix}/SHOW`;
export const HIDE = `${actionNamePrefix}/HIDE`;
export const CONFIRM = `${actionNamePrefix}/CONFIRM`;
export const SUBMIT = `${actionNamePrefix}/SUBMIT`;
export const CANCEL = `${actionNamePrefix}/CANCEL`;
export const WAIT = `${actionNamePrefix}/WAIT`;
export const DESTROY = `${actionNamePrefix}/DESTROY`;

export const show = (props) => ({
  type: SHOW,
  ...props,
});
export const hide = (props) => ({
  type: HIDE,
  ...props,
});

export const confirm = (props) => ({
  type: CONFIRM,
  ...props,
});
export const submit = (props) => ({
  type: SUBMIT,
  ...props,
});

export const cancel = (props) => ({
  type: CANCEL,
  ...props,
});

export const wait = (props) => ({
  type: WAIT,
  ...props,
});

export const destroy = (props) => ({
  type: DESTROY,
  ...props,
});
