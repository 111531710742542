import { Fragment } from 'react';

export function augmentError(error) {
  if (!window.navigator.onLine)
    return {
      title: <Fragment>Network error!</Fragment>,
      description: (
        <Fragment>
          It looks like your are offline. Please check your network connection.
        </Fragment>
      ),
    };
  if (error?.message === 'Network Error')
    return {
      title: <Fragment>Network error!</Fragment>,
      description: <Fragment>Please check your network connection.</Fragment>,
    };
  return;
}
