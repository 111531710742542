import { Fragment } from 'react';
import { Button as RsButton } from 'rsuite';
import classNames from 'classnames';

const LoaderError = ({
  onRetry,
  message = '',
  retryable = false,
  className = '',
}) => {
  return (
    <div className={classNames(className)}>
      <div className="l-flex__item--overflow">
        {message}
        {retryable && (
          <Fragment>
            {' '}
            Please click{' '}
            <RsButton
              appearance="link"
              className="rs-btn-inline"
              onClick={onRetry}
            >
              here
            </RsButton>{' '}
            to try again.
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default LoaderError;
