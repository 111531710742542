import { Fragment, useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import { abortRequests } from '@redux-requests/core';
import { Loader as RsLoader } from 'rsuite';
import classNames from 'classnames';

import LoaderError from 'app/components/LoaderError';
import * as totoEditActions from 'app/services/toto/edit/actions';

const Loader = ({ className, children, repository }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const totoEditSearchUserInputDispatchConfigsQuery = useQuery({
    type: totoEditActions.SEARCH_USER_INPUT_DISPATCH_CONFIGS,
  });

  const search = useCallback(
    () =>
      dispatch(
        totoEditActions.searchUserInputDispatchConfigs({
          payload: {
            repositoryId: repository.id,
          },
        }),
      ).finally(() => setIsInitialized(true)),
    [dispatch, repository.id],
  );

  useEffect(() => {
    search();
  }, [search]);

  useEffect(
    () => () => {
      dispatch(
        abortRequests([totoEditActions.SEARCH_USER_INPUT_DISPATCH_CONFIGS]),
      );
    },
    [dispatch],
  );

  return (
    <Fragment>
      {!isInitialized || totoEditSearchUserInputDispatchConfigsQuery.loading ? (
        <div
          className={classNames(
            className,
            'l-flex',
            'l-flex--column',
            'l-flex--center-center',
          )}
        >
          <RsLoader size="md" speed="slow" />
        </div>
      ) : totoEditSearchUserInputDispatchConfigsQuery.error ? (
        <LoaderError
          onRetry={search}
          message="Dispatch configs could not be loaded."
          retryable
          className="l-flex l-flex--column l-flex--center-center l-flex__item--main"
        ></LoaderError>
      ) : children instanceof Function ? (
        children(totoEditSearchUserInputDispatchConfigsQuery.data)
      ) : (
        children
      )}
    </Fragment>
  );
};

export default Loader;
