import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';

import { assembleTitle } from 'app/services/title';

import styles from './PrivacyPolicy.module.scss';

const Legal = ({ parentTitle = '' }) => {
  const title = useMemo(() => assembleTitle('Legal', parentTitle), [
    parentTitle,
  ]);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classNames(styles.content)}>
        <p>Last updated at 06.03.2021</p>
        <h2>1. General information and scope of application</h2>
        <p>
          We, Nebelflucht GmbH (<strong>"we "</strong>, <strong>"our "</strong>{' '}
          or <strong>"us "</strong>) collect, process and use personal data of
          users (<strong>"users "</strong>, <strong>"your "</strong> or{' '}
          <strong>"you "</strong>) only in compliance with the existing data
          protection regulations. The legal framework for data protection is
          provided by the General Data Protection Regulation (GDPR), the Federal
          Data Protection Act (BDSG) and the Telemedia Act (TMG). The protection
          of personal data is an important concern for us. In the following, you
          will learn which personal data can be created in which way at our
          website{' '}
          <a
            href="https://create.toto.io"
            rel="nofollow noreferrer noopener"
            target="_blank"
          >
            https://create.toto.io
          </a>{' '}
          (<strong>"website"</strong>) or by using our services and how you
          maintain control over your data.
        </p>
        <h3>1.1. We act upon the principle of thrift data</h3>
        <p>
          We collect, process and use data according to the principle of thrift
          data. The submission of personal data at the website is voluntary and
          is limited exclusively to the purpose-related scope. We store personal
          data in accordance with the principles of data avoidance and thrift of
          data only as long as it is necessary or legally prescribed.
        </p>
        <p>
          If the purpose ceases to apply and there is no obligation to retain
          data, we will block or delete the data in due time. Further
          transmission of the data will not take place or will only take place
          if you have expressly consented to the transmission. Your data will
          not be passed on to third parties without your express consent, for
          example for advertising purposes. The basis for data processing is
          article 6 (1)(b) GDPR, which permits the processing of data for the
          fulfilment of a contract or pre-contractual measures.
        </p>
        <h3>1.2. You're in control of your data</h3>
        <p>
          You have the right at any time to free information about the personal
          data stored by us and/or correction, blocking or deletion of the same,
          unless they are required for existing contract fulfilment or are
          subject to the statutory storage obligation. A corresponding contact
          is available to you for these purposes:
        </p>
        <p>
          Nebelflucht GmbH
          <br />
          Gerichtstraße 12-13
          <br />
          Aufgang 6<br />
          13347 Berlin
          <br />
          Germany
          <br />
          <a href="mailto:info@toto.io">info@toto.io</a>
        </p>
        <h3>1.3. Encrypted data transmission</h3>
        <p>
          The app uses SSL encryption for security reasons and to protect the
          transmission of personal data and other confidential content. You can
          recognize an encrypted connection by the string{' '}
          <strong>https://</strong> in the URL and by a corresponding
          visualization in your browser.
        </p>
        <hr />
        <h2>2. Areas of application for personal data</h2>
        <h3>2.1. Logging information</h3>
        <p>
          When you access the website, information is automatically stored by
          the web server. This information is of a general nature and cannot be
          traced back to you personally. These so-called log files contain the
          following information:
        </p>
        <ul>
          <li>name of website</li>
          <li>file</li>
          <li>date</li>
          <li>amount of data</li>
          <li>web browser and it's version</li>
          <li>operating system</li>
          <li>domain name of your internet provider</li>
          <li>referrer URL (the page from which you access our offer)</li>
          <li>IP address</li>
        </ul>
        <p>
          This data is exchanged between the providers through every surfing
          behaviour in the background and serves the exchange of information in
          order to guarantee the technical basis for the requested presentation
          of website content. The collection of data is therefore absolutely
          necessary.
        </p>
        <p>
          We reserve the right to subsequently check any log files that have
          arisen in the event of suspicion of illegal use of the offer. If
          necessary, we can also use this data to optimise our offer or for our
          own statistical purposes.
        </p>
        <h3>2.2. Cookies</h3>
        <p>
          The website uses so-called cookies; text files that are stored on your
          device. These are only used to authenticate you as a user and do not
          contain any personal data.
        </p>
        <h3>2.3. Contact</h3>
        <p>
          If you contact us through the contact options offered, your data will
          be stored for a period of six months so that it can be used to process
          and answer your enquiry and in the event of follow-up questions. This
          data will not be passed on to third parties.
        </p>
        <h3>2.4. Analyse services</h3>
        <p>We do not use analysis services such as Google Analytics.</p>
        <h3>2.5 Toto specific data processing</h3>
        <p>
          Toto enables users registered as authors (<strong>"Author"</strong>)
          to create contents, upload pictures and evaluate user activities
          within a workspace (<strong>"Repository"</strong>). The data generated
          in the process can only be accessed by authors within this repository.
        </p>
        <p>
          The processing of Toto-specific data is the core of Toto and is
          therefore carried out in accordance with article 6 (1)(f) GDPR.
        </p>
        <hr />
        <h2>3. Reservation of changes</h2>
        <p>
          Technology is subject to constant change. We try to check innovations
          and updates of existing applications for their compatibility with the
          legal requirements. This may also mean that we must constantly change
          or expand our privacy policy. We therefore reserve the right to change
          our privacy policy. The new privacy policy will then take effect the
          next time you use our services.
        </p>
      </div>
    </Fragment>
  );
};

export default Legal;
