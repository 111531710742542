import { Notification as RsNotification } from 'rsuite';

export function show({ type = 'info', description, title, duration = 6000 }) {
  RsNotification[type]({
    className: !description && 'rs-notification-no-description',
    title,
    description,
    duration,
  });
}
