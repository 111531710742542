import { all, takeLatest, call } from 'redux-saga/effects';
import { Fragment } from 'react';
import { success, error } from '@redux-requests/core';

import * as rsNotificationUtils from 'app/services/rsuite/notification';
import * as apiUtils from 'app/services/toto/api';
import * as actions from './actions';

function* fetchIdentityErrorSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>Identity could not be loaded!</Fragment>,
    description: apiUtils.augmentError(action?.error)?.description,
    type: 'error',
  });
}

function* logInSuccessSaga() {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>You have successfully logged in!</Fragment>,
    type: 'success',
  });
}
function* logInErrorSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>Login failed!</Fragment>,
    description: apiUtils.augmentError(action?.error)?.description,
    type: 'error',
  });
}

function* logOutSuccessSaga() {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>You have successfully logged out!</Fragment>,
    type: 'success',
  });
}
function* logOutErrorSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>Logout failed!</Fragment>,
    description: apiUtils.augmentError(action?.error)?.description,
    type: 'error',
  });
}

export default function* saga() {
  yield all([
    takeLatest(error(actions.FETCH_IDENTITY), fetchIdentityErrorSaga),
    takeLatest(success(actions.LOG_IN), logInSuccessSaga),
    takeLatest(error(actions.LOG_IN), logInErrorSaga),
    takeLatest(success(actions.LOG_OUT), logOutSuccessSaga),
    takeLatest(error(actions.LOG_OUT), logOutErrorSaga),
  ]);
}
