import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { handleRequests, success, error } from '@redux-requests/core';
import { createDriver } from '@redux-requests/axios';
import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import rootReducer from 'app/reducers';
import rootSaga from 'app/sagas';
import totoAuthSaga from 'app/services/toto/auth/saga';
import totoAccountSaga from 'app/services/toto/account/saga';
import totoOpSaga from 'app/services/toto/op/saga';

const createSagaActionCallbackMiddleware = () => (store) => (next) => (
  action,
) => {
  if (action.meta?.__on__ && action.type && action.meta?.requestAction) {
    switch (action.type) {
      case success(action.meta.requestAction.type):
        if (action.meta.__on__.success instanceof Function) {
          action.meta.__on__.success(action, store);
        }
        break;
      case error(action.meta.requestAction.type):
        if (action.meta.__on__.error instanceof Function) {
          action.meta.__on__.error(action, store);
        }
        break;
      default:
        break;
    }
  }

  return next(action);
};

function* combinedSaga() {
  yield all([rootSaga(), totoAuthSaga(), totoAccountSaga(), totoOpSaga()]);
}

export default function configure(axiosInstance) {
  const sagaMiddleware = createSagaMiddleware();
  const sagaActionCallbackMiddleware = createSagaActionCallbackMiddleware();

  const { requestsReducer, requestsMiddleware } = handleRequests({
    driver: createDriver(axiosInstance),
  });

  const reducers = combineReducers({
    ...rootReducer,
    requests: requestsReducer,
  });

  const composeEnhancers =
    (typeof window !== 'undefined' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const store = createStore(
    reducers,
    composeEnhancers(
      applyMiddleware(
        sagaActionCallbackMiddleware,
        sagaMiddleware,
        ...requestsMiddleware,
      ),
    ),
  );

  sagaMiddleware.run(combinedSaga);

  return store;
}
