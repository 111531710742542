import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Sidenav as RsSidenav,
  Container as RsContainer,
  Sidebar as RsSidebar,
  Nav as RsNav,
} from 'rsuite';
import { useLocation, Route, Switch, NavLink } from 'react-router-dom';

import DispatchConfig from './DispatchConfig';
import NotFound from 'app/components/NotFound';
import LoaderError from 'app/components/LoaderError';
import pathJoin from 'app/services/utils/pathJoin';
import pathContains from 'app/services/utils/pathContains';
import UserInputDispatchConfigLoader from 'app/components/UserInput/DispatchConfig/Loader';
import { assembleTitle } from 'app/services/title';

const sideNavWidth = 260;

const SubDomain = {
  DispatchConfig: DispatchConfig,
};

const UserInput = ({ parentTitle = '', repository }) => {
  const title = useMemo(() => assembleTitle('User input', parentTitle), [
    parentTitle,
  ]);

  const location = useLocation();

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <UserInputDispatchConfigLoader
        repository={repository}
        className="l-flex__item--main l-flex__item--overflow"
      >
        {(userInputDispatchConfigs = []) => (
          <Fragment>
            {userInputDispatchConfigs.length > 0 ? (
              <RsContainer className="full-height">
                <RsSidebar width={sideNavWidth} className="rs-container">
                  <RsSidenav expanded>
                    <RsSidenav.Body>
                      <RsNav>
                        {userInputDispatchConfigs.map(
                          (userInputDispatchConfig) => (
                            <RsNav.Item
                              key={userInputDispatchConfig.id}
                              componentClass={NavLink}
                              to={pathJoin(
                                '/repo',
                                repository.slug,
                                'user-input',
                                userInputDispatchConfig.name,
                              )}
                              active={pathContains(
                                location.pathname,
                                pathJoin(
                                  '/repo',
                                  repository.slug,
                                  'user-input',
                                  userInputDispatchConfig.name,
                                ),
                              )}
                            >
                              {userInputDispatchConfig.name}
                            </RsNav.Item>
                          ),
                        )}
                      </RsNav>
                    </RsSidenav.Body>
                  </RsSidenav>
                </RsSidebar>
                <RsContainer className="full-height">
                  <Switch>
                    <Route
                      path={pathJoin('/repo', repository.slug, 'user-input')}
                      exact
                    >
                      <LoaderError
                        message="Please select a dispatch config."
                        className="l-flex l-flex--column l-flex--center-center l-flex__item--main"
                      ></LoaderError>
                    </Route>
                    <Route
                      path={pathJoin(
                        '/repo',
                        repository.slug,
                        'user-input/:userInputDispatchConfigName',
                      )}
                    >
                      <SubDomain.DispatchConfig
                        repository={repository}
                        userInputDispatchConfigs={userInputDispatchConfigs}
                        parentTitle={title}
                      />
                    </Route>
                    <Route>
                      <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
                        <NotFound parentTitle={title} />
                      </div>
                    </Route>
                  </Switch>
                </RsContainer>
              </RsContainer>
            ) : (
              <LoaderError
                message="No dispatch configs found."
                className="l-flex l-flex--column l-flex--center-center l-flex__item--main"
              ></LoaderError>
            )}
          </Fragment>
        )}
      </UserInputDispatchConfigLoader>
    </Fragment>
  );
};

for (let key in SubDomain) {
  UserInput[key] = SubDomain[key];
}

export default UserInput;
