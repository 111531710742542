import { Fragment, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@redux-requests/react';

import { assembleTitle } from 'app/services/title';
import LogInForm from 'app/components/LogIn';
import * as totoAuthActions from 'app/services/toto/auth/actions';

const LogIn = ({ parentTitle = '' }) => {
  const title = useMemo(() => assembleTitle('Log in', parentTitle), [
    parentTitle,
  ]);

  const totoAuthFetchIdentityQuery = useQuery({
    type: totoAuthActions.FETCH_IDENTITY,
  });

  return (
    <Fragment>
      {totoAuthFetchIdentityQuery.data?.id ? (
        <Redirect to="/" />
      ) : (
        <Fragment>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
            <LogInForm />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LogIn;
