const pathJoin = (...args) =>
  args
    .map((part, i) => {
      if (i === 0) {
        return String(part).trim().replace(/[/]*$/g, '');
      } else {
        return String(part)
          .trim()
          .replace(/(^[/]*|[/]*$)/g, '');
      }
    })
    .filter((x) => x.length)
    .join('/');

export default pathJoin;
