import Main from './Main';
import LogIn from './LogIn';
import Legal from './Legal';
import PrivacyPolicy from './PrivacyPolicy';

const DomainsByName = {
  Main,
  LogIn,
  Legal,
  PrivacyPolicy,
};

export default DomainsByName;
