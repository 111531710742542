import { NavLink } from 'react-router-dom';
import { List as RsList, Avatar as RsAvatar, Icon as RsIcon } from 'rsuite';
import classNames from 'classnames';

import styles from './RepositoryList.module.scss';

const RepositoryList = ({
  navLinkBaseUrl = '',
  className,
  repositories = [],
}) => {
  return (
    <RsList hover className={classNames(className)}>
      {repositories.map((repository, index) => (
        <RsList.Item
          key={repository.id}
          index={index}
          className={styles['list__item']}
        >
          <div
            className={classNames(
              styles['l-flex--with-gap'],
              'l-flex',
              'l-flex--row',
              'l-flex--center-center',
            )}
          >
            <RsAvatar
              src={
                repository.defaults.content.logo &&
                `/v2/pub/file/${repository.defaults.content.logo}?v=s`
              }
              alt={repository.name}
            >
              <RsIcon icon="database" />
            </RsAvatar>
            <div className="l-flex__item--main">
              <div>
                <strong>{repository.name}</strong>
              </div>
              <div>
                <small>{repository.permission.role?.toLowerCase()}</small>
              </div>
            </div>
            {!repository.__disabledInList && (
              <div>
                <NavLink to={`${navLinkBaseUrl}${repository.slug}`}>
                  view
                </NavLink>
              </div>
            )}
          </div>
        </RsList.Item>
      ))}
    </RsList>
  );
};

export default RepositoryList;
