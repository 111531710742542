import { all, takeLatest, call } from 'redux-saga/effects';
import { Fragment } from 'react';
import { error } from '@redux-requests/core';

import * as rsNotificationUtils from 'app/services/rsuite/notification';
import * as apiUtils from 'app/services/toto/api';
import * as actions from './actions';

function* fetchErrorSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>Account could not be loaded!</Fragment>,
    description: apiUtils.augmentError(action?.error)?.description,
    type: 'error',
  });
}

export default function* saga() {
  yield all([takeLatest(error(actions.FETCH), fetchErrorSaga)]);
}
