import { Fragment } from 'react';
import * as networkUtils from 'app/services/network';

export const augmentError = (error) => {
  if (!window.navigator.onLine || error?.message === 'Network Error') {
    return networkUtils.augmentError(error);
  }

  let result;

  if (error?.response?.data?.code) {
    switch (error.response.data.code) {
      case 'common:31':
        switch (error.response.data.message) {
          case 'totoObject':
          case '!totoObject':
            result = {
              title: <Fragment>Object not found!</Fragment>,
              // description: <Fragment>No object found with the specified key and secret.</Fragment>,
            };
            break;
          case 'repository':
          case '/repository':
            result = {
              title: <Fragment>Repository not found!</Fragment>,
            };
            break;
          default:
            break;
        }
        break;
      case undefined:
        break;
      default:
        break;
    }
  }

  if (!result) {
    if (error?.response?.data?.message) {
      result = {
        title: error.response.statusText,
        description: error.response.data.message,
      };
    } else {
      if (error?.response?.statusText || error?.message) {
        result = {
          title: error?.response?.statusText,
          description: error?.message,
        };
      }
    }
  }

  return result;
};
