import { useMemo, useRef, useEffect, useState } from 'react';
import { Form as RsForm, Schema as RsSchema } from 'rsuite';

import getRandomString from 'app/services/utils/getRandomString';

const FormControlled = ({
  onReady,
  onChange,
  initialValue = {},
  rsFormModelDef = {},
  children,
  rsFormRef: _rsFormRef,
  ...props
}) => {
  const rsFormId = useMemo(() => `rsform-${getRandomString(16)}`, []);

  const [rsFormValue, setRsFormValue] = useState({ ...initialValue });

  const rsFormRef = useRef(null);

  const rsFormModel = useMemo(() => RsSchema.Model(rsFormModelDef), [
    rsFormModelDef,
  ]);

  useEffect(() => {
    if (onReady instanceof Function) {
      onReady({
        ref: rsFormRef,
        setValue: setRsFormValue,
      });
    }

    if (_rsFormRef) {
      _rsFormRef.current = rsFormRef.current;
    }
  }, [rsFormRef, onReady, _rsFormRef]);

  useEffect(() => {
    if (onChange instanceof Function) onChange(rsFormValue);
  }, [onChange, rsFormValue]);

  return (
    <RsForm
      id={rsFormId}
      fluid
      ref={rsFormRef}
      model={rsFormModel}
      formValue={rsFormValue}
      onChange={setRsFormValue}
      {...props}
    >
      {children}
    </RsForm>
  );
};

export default FormControlled;
