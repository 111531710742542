import { createContext, useState, useMemo } from 'react';

export const Context = createContext({
  subNav: null,
  setSubNav: () => {},
  navbarHeaderExtension: null,
  setNavbarHeaderExtension: () => {},
});

export const Provider = ({
  subNav: initialSubNav = null,
  navbarHeaderExtension: initialNavbarHeaderExtension = null,
  children,
}) => {
  const [subNav, setSubNav] = useState(initialSubNav);
  const [navbarHeaderExtension, setNavbarHeaderExtension] = useState(
    initialNavbarHeaderExtension,
  );

  const contextValue = useMemo(
    () => ({
      subNav,
      setSubNav,
      navbarHeaderExtension,
      setNavbarHeaderExtension,
    }),
    [navbarHeaderExtension, subNav],
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
