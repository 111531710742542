import { Fragment, useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import { abortRequests } from '@redux-requests/core';
import { Loader as RsLoader } from 'rsuite';
import classNames from 'classnames';

import LoaderError from 'app/components/LoaderError';
import * as totoAccountActions from 'app/services/toto/account/actions';
import * as totoAuthActions from 'app/services/toto/auth/actions';

const validRepositoryPermissions = ['Owner', 'Author', 'Operator'];

const Loader = ({ className, children }) => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const totoAuthFetchIdentityQuery = useQuery({
    type: totoAuthActions.FETCH_IDENTITY,
  });

  const totoAccountFetchQuery = useQuery({
    type: totoAccountActions.FETCH,
  });

  const fetch = useCallback(
    () =>
      dispatch(
        totoAccountActions.fetch({
          payload: {
            id: totoAuthFetchIdentityQuery.data.id,
          },
        }),
      ).finally(() => setIsInitialized(true)),
    [dispatch, totoAuthFetchIdentityQuery.data.id],
  );

  const repositories = useMemo(
    () =>
      totoAccountFetchQuery.data?.repositories?.map((repository) => ({
        ...repository,
        __disabledInList: !validRepositoryPermissions.includes(
          repository.permission.role,
        ),
      })) || [],
    [totoAccountFetchQuery.data],
  );

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(
    () => () => {
      dispatch(abortRequests([totoAccountActions.FETCH]));
    },
    [dispatch],
  );

  return (
    <Fragment>
      {!isInitialized || totoAccountFetchQuery.loading ? (
        <div
          className={classNames(
            className,
            'l-flex',
            'l-flex--column',
            'l-flex--center-center',
          )}
        >
          <RsLoader size="md" speed="slow" />
        </div>
      ) : totoAccountFetchQuery.error ? (
        <LoaderError
          onRetry={fetch}
          message="Account could not be loaded."
          retryable
          className="l-flex l-flex__item--main l-flex--column l-flex--center-center"
        ></LoaderError>
      ) : children instanceof Function ? (
        children(repositories)
      ) : (
        children
      )}
    </Fragment>
  );
};

export default Loader;
