import { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalForm from 'app/components/ModalForm/ModalForm';
import * as modalActions from 'app/actions/modal/modal';

const Modal = () => {
  const dispatch = useDispatch();

  const modal = useSelector(({ modal }) => {
    return modal;
  });

  const onClosed = () => {
    dispatch(modalActions.destroy());
  };

  return (
    <Fragment>
      {modal && modal.type && (
        <Fragment>
          {!modal.type.indexOf('form') && (
            <ModalForm onClosed={onClosed} {...modal} />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Modal;
