import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';

import NotFound from 'app/components/NotFound';
import PrivateRoute from 'app/components/PrivateRoute';
import AccountLoader from 'app/components/Account/Loader';
import AccountRepositoryList from 'app/components/Account/RepositoryList';
import Repository from './Repository';
import { assembleTitle } from 'app/services/title';

const SubDomain = {
  Repository: Repository,
};

const Main = ({ parentTitle = '', ...props }) => {
  const title = useMemo(() => assembleTitle('', parentTitle), [parentTitle]);
  const routeMatch = useRouteMatch();

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Switch>
        <PrivateRoute path="/" exact key={`${routeMatch.url}_/`}>
          <AccountLoader className="l-flex__item--main l-flex__item--overflow">
            {(repositories) => (
              <AccountRepositoryList
                className={classNames(props?.className)}
                navLinkBaseUrl="/repo/"
                repositories={repositories}
              />
            )}
          </AccountLoader>
        </PrivateRoute>
        <PrivateRoute
          path="/repo/:repositorySlug"
          key={`${routeMatch.url}_/repo/:repositorySlug`}
        >
          <AccountLoader className="l-flex__item--main l-flex__item--overflow">
            <SubDomain.Repository parentTitle={title} {...props} />
          </AccountLoader>
        </PrivateRoute>
        <Route>
          <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
            <NotFound parentTitle={title} />
          </div>
        </Route>
      </Switch>
    </Fragment>
  );
};

for (let key in SubDomain) {
  Main[key] = SubDomain[key];
}

export default Main;
