import { Fragment, useMemo, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  useParams,
  useLocation,
  Redirect,
  Route,
  Switch,
  NavLink,
} from 'react-router-dom';
import { useQuery } from '@redux-requests/react';
import { Nav as RsNav, Avatar as RsAvatar } from 'rsuite';

import { Context as HeaderContext } from 'app/components/Header/Context';
import UserInput from './UserInput';
import pathJoin from 'app/services/utils/pathJoin';
import pathContains from 'app/services/utils/pathContains';
import NotFound from 'app/components/NotFound';
import { assembleTitle } from 'app/services/title';
import * as totoAccountActions from 'app/services/toto/account/actions';

const SubDomain = {
  UserInput: UserInput,
};

const HeaderSubNav = ({ repository }) => {
  const location = useLocation();

  return (
    <Fragment>
      <RsNav.Item
        componentClass={NavLink}
        to={pathJoin('/repo', repository.slug, 'user-input')}
        active={pathContains(
          location.pathname,
          pathJoin('/repo', repository.slug, 'user-input'),
        )}
      >
        user input
      </RsNav.Item>
    </Fragment>
  );
};

const HeaderNavBarHeaderExtension = ({ repository }) => {
  return (
    <Fragment>
      {repository.defaults.content.logo && (
        <RsAvatar
          src={`/v2/pub/file/${repository.defaults.content.logo}?v=s`}
          alt={repository.name}
          className="repository-logo"
        />
      )}
      <div className="rs-navbar-caption">{repository.name}</div>
    </Fragment>
  );
};

const Repository = ({ parentTitle = '', ...props }) => {
  const { repositorySlug } = useParams();

  const totoAccountFetchQuery = useQuery({
    type: totoAccountActions.FETCH,
  });

  const repository = useMemo(
    () =>
      totoAccountFetchQuery.data?.repositories?.find(
        (repository) => repository.slug === repositorySlug,
      ),
    [repositorySlug, totoAccountFetchQuery.data],
  );

  const title = useMemo(() => assembleTitle(repositorySlug, parentTitle), [
    repositorySlug,
    parentTitle,
  ]);

  const headerContext = useContext(HeaderContext);

  useEffect(() => {
    if (repository?.id) {
      headerContext.setSubNav(<HeaderSubNav repository={repository} />);
      headerContext.setNavbarHeaderExtension(
        <HeaderNavBarHeaderExtension repository={repository} />,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repository]);

  useEffect(
    () => () => {
      headerContext.setSubNav();
      headerContext.setNavbarHeaderExtension();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Fragment>
      {repository?.id ? (
        <Switch>
          <Route path={pathJoin('/repo', repository.slug, 'user-input')}>
            <SubDomain.UserInput
              parentTitle={title}
              repository={repository}
              {...props}
            />
          </Route>
          <Route path={pathJoin('/repo', repository.slug)} exact>
            <Fragment>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <Redirect to={pathJoin('/repo', repository.slug, 'user-input')} />
            </Fragment>
          </Route>
          <Route>
            <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
              <NotFound parentTitle={parentTitle} />
            </div>
          </Route>
        </Switch>
      ) : (
        <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
          <NotFound
            parentTitle={parentTitle}
            message={
              <Fragment>
                Repository with slug <strong>{repositorySlug}</strong> not
                found!
              </Fragment>
            }
          />
        </div>
      )}
    </Fragment>
  );
};

for (let key in SubDomain) {
  Repository[key] = SubDomain[key];
}

export default Repository;
