import { apiRootUrl } from './config';

const actionNamePrefix = 'TOTO/AUTH';

export const FETCH_IDENTITY = `${actionNamePrefix}/FETCH_IDENTITY`;
export const LOG_IN = `${actionNamePrefix}/LOG_IN`;
export const LOG_OUT = `${actionNamePrefix}/LOG_OUT`;

export const fetchIdentity = ({ meta = {} } = {}) => ({
  type: FETCH_IDENTITY,
  request: {
    url: `${apiRootUrl}/identity`,
    method: 'GET',
  },
  meta: {
    ...meta,
  },
});

export const logIn = ({ payload, meta = {} } = {}) => ({
  type: LOG_IN,
  request: {
    url: `${apiRootUrl}/login`,
    method: 'POST',
    data: payload,
  },
  meta: {
    ...meta,
    mutations: {
      [`${FETCH_IDENTITY}`]: (data, mutationData) => mutationData,
    },
  },
});

export const logOut = ({ meta = {} } = {}) => ({
  type: LOG_OUT,
  request: {
    url: `${apiRootUrl}/logout`,
    method: 'POST',
  },
  meta: {
    ...meta,
    mutations: {
      [`${FETCH_IDENTITY}`]: () => ({}),
    },
  },
});
