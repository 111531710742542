import { Fragment, useState, useEffect, useRef } from 'react';
import classNames from 'classnames';

const Backdrop = ({ isShown, className = '' }) => {
  const [isExisting, setIsExisting] = useState();
  const [isAnimatingToBeVisible, setIsAnimatingToBeVisible] = useState();

  const timeoutRef = useRef();

  useEffect(() => {
    if (isShown) {
      setIsExisting(true);

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setIsAnimatingToBeVisible(true));
    } else {
      setIsAnimatingToBeVisible(false);

      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => setIsExisting(false), 150);
    }
  }, [isShown]);

  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  return (
    <Fragment>
      {isExisting && (
        <div
          className={`${className} fade ${classNames({
            in: isAnimatingToBeVisible,
          })}`}
        ></div>
      )}
    </Fragment>
  );
};

export default Backdrop;
