import { Fragment, useMemo, useCallback, useRef } from 'react';
import {
  FormGroup as RsFormGroup,
  ControlLabel as RsControlLabel,
  Schema as RsSchema,
  Input as RsInput,
  FormControl as RsFormControl,
} from 'rsuite';

import FormControlled from 'app/components/FormControlled/FormControlled';

const CustomDispatchForm = ({ onChange, onReady, initialValue = {} }) => {
  const formControlledRef = useRef({});

  const onFormControlledChange = useCallback(
    (value) => {
      if (onChange instanceof Function) {
        onChange(value);
      }
    },
    [onChange],
  );

  const onFormControlledReady = useCallback(
    (props) => {
      if (onReady instanceof Function) {
        onReady(props);
      }

      formControlledRef.current = {
        ...props,
      };
    },
    [onReady],
  );

  const rsFormModelDef = useMemo(
    () => ({
      payload: RsSchema.Types.StringType().isRequired(
        'This field is required.',
      ),
    }),
    [],
  );

  return (
    <Fragment>
      <FormControlled
        initialValue={initialValue}
        onChange={onFormControlledChange}
        onReady={onFormControlledReady}
        rsFormModelDef={rsFormModelDef}
      >
        <RsFormGroup key={`form-group-payload`}>
          <RsControlLabel>Payload</RsControlLabel>
          <RsFormControl
            name="payload"
            accepter={RsInput}
            componentClass="textarea"
            placeholder="payload"
          />
        </RsFormGroup>
      </FormControlled>
    </Fragment>
  );
};

export default CustomDispatchForm;
