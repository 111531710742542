import { Route, Redirect } from 'react-router-dom';
import { useQuery } from '@redux-requests/react';

import * as totoAuthActions from 'app/services/toto/auth/actions';

const PrivateRoute = ({ children, ...props }) => {
  const totoAuthFetchIdentityQuery = useQuery({
    type: totoAuthActions.FETCH_IDENTITY,
  });

  return (
    <Route
      {...props}
      render={() =>
        totoAuthFetchIdentityQuery.data?.id ? (
          children
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
