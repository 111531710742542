import {
  Dropdown as RsDropdown,
  Progress as RsProgress,
  Loader as RsLoader,
} from 'rsuite';
import classNames from 'classnames';

const Item = ({ loading, progress, ...props }) => {
  return (
    <RsDropdown.Item
      className={`${props?.className || ''} ${classNames({
        'rs-dropdown-item-progressing': loading && progress < 100,
        'rs-dropdown-item-loading': loading && !(progress > -1),
      })}`}
      {...props}
    >
      {loading && progress < 100 && (
        <RsProgress.Circle
          percent={progress}
          showInfo={false}
          status="active"
        />
      )}
      {loading && !(progress > -1) && <RsLoader size="xs" speed="slow" />}
      {props.children}
    </RsDropdown.Item>
  );
};

export default Item;
