const getRandomString = (length) => {
  var arr = new window.Uint8Array(Math.ceil(length + 2 / 2));

  window.crypto.getRandomValues(arr);

  return [].map
    .call(arr, function (n) {
      return n.toString(16);
    })
    .join('')
    .substr(0, length);
};
export default getRandomString;
