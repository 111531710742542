import { useCallback } from 'react';
import {
  List as RsList,
  Avatar as RsAvatar,
  Button as RsButton,
  ButtonToolbar as RsButtonToolbar,
  Tag as RsTag,
  TagGroup as RsTagGroup,
} from 'rsuite';
import classNames from 'classnames';
import { useMutation } from '@redux-requests/react';
import { useDispatch } from 'react-redux';

import * as totoOpActions from 'app/services/toto/op/actions';

import styles from './Item.module.scss';

const Item = ({
  userInput,
  index,
  repository,
  config,
  hasActions,
  onReject,
  onDispatch,
  status,
}) => {
  const dispatch = useDispatch();

  const dispatchConfig = useCallback(
    () =>
      dispatch(
        totoOpActions.dispatchConfig({
          payload: {
            repositoryId: repository.id,
            configId: config.id,
            sessionId: userInput.sessionId,
            elementId: userInput.elementId,
            accountId: userInput.account.id,
            userInputId: userInput.id,
          },
        }),
      ).then(({ error }) => {
        if (!error && onDispatch instanceof Function) {
          onDispatch(userInput);
        }
      }),
    [config.id, dispatch, repository.id, userInput, onDispatch],
  );

  const totoOpDispatchConfigQuery = useMutation({
    type: totoOpActions.DISPATCH_CONFIG,
    requestKey: `${userInput.id}:${config.id}`,
  });

  return (
    <RsList.Item
      index={index}
      className={classNames(styles['list__item'], styles.root)}
    >
      <small>{userInput.processedAtToLocaleString}</small>
      <blockquote
        className={classNames(styles.blockquote, 'l-flex__item--main')}
      >
        {userInput.input.file?.contentType?.indexOf('image/') === 0 && (
          <a
            href={`/v2/edit/${repository.id}/monitoring/input/file/${userInput.id}?v=orig`}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={`/v2/edit/${repository.id}/monitoring/input/file/${userInput.id}?v=s`}
              srcSet={`/v2/edit/${repository.id}/monitoring/input/file/${userInput.id}?v=s 400w,/v2/edit/${repository.id}/monitoring/input/file/${userInput.id}?v=m 1200w`}
              sizes={`screen and ((min-resolution: 2dppx) or (min-resolution: 192dpi) or (-webkit-min-device-pixel-ratio: 2)) 1200px, 400px`}
              alt="user input"
              className={classNames(styles.image)}
              loading="lazy"
            />
          </a>
        )}
        <p>{userInput.input.text}</p>
        <cite className={classNames(styles.cite)}>
          <RsAvatar
            className={classNames(styles.avatar)}
            src={
              userInput.account.avatar &&
              `/v2/account/${userInput.account.avatar}/avatar?v=thumb`
            }
            alt={userInput.account.username}
            size="xs"
            circle
          >
            {userInput.account.username.slice(0, 1).toUpperCase()}
          </RsAvatar>{' '}
          <span className={styles.screenName}>
            {userInput.account.screenName}
          </span>
          <span className={styles.username}>
            (<small>@{userInput.account.username}</small>)
          </span>
        </cite>
      </blockquote>

      {hasActions && (
        <RsButtonToolbar className={classNames(styles.buttonToolBar)}>
          <RsButton
            appearance="link"
            color="red"
            onClick={() => {
              if (onReject instanceof Function) {
                onReject(userInput);
              }
            }}
            size="sm"
          >
            reject
          </RsButton>
          <RsButton
            loading={totoOpDispatchConfigQuery.loading}
            appearance="primary"
            onClick={dispatchConfig}
            size="sm"
          >
            confirm
          </RsButton>
        </RsButtonToolbar>
      )}

      {status && (
        <RsTagGroup className={styles.tagGroup}>
          <RsTag className={styles[status]}>{status}</RsTag>
        </RsTagGroup>
      )}
    </RsList.Item>
  );
};

export default Item;
