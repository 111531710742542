import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import classNames from 'classnames';

import { assembleTitle } from 'app/services/title';

import styles from './Legal.module.scss';

const Legal = ({ parentTitle = '' }) => {
  const title = useMemo(() => assembleTitle('Legal', parentTitle), [
    parentTitle,
  ]);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className={classNames(styles.content)}>
        <h2>Nebelflucht GmbH</h2>
        <p>
          Gerichtstraße 12-13
          <br />
          Aufgang 6<br />
          13347 Berlin
          <br />
          Germany
          <br />
          <a href="mailto:info@toto.io">info@toto.io</a>
        </p>
        <hr />
        <h2>Limitation of liability for internal content</h2>
        <p>
          The content of our website has been compiled with meticulous care and
          to the best of our knowledge. However, we cannot assume any liability
          for the up-to-dateness, completeness or accuracy of any of the pages.
        </p>
        <p>
          Pursuant to section 7, para. 1 of the TMG (Telemediengesetz – Tele
          Media Act by German law), we as service providers are liable for our
          own content on these pages in accordance with general laws. However,
          pursuant to sections 8 to 10 of the TMG, we as service providers are
          not under obligation to monitor external information provided or
          stored on our website. Once we have become aware of a specific
          infringement of the law, we will immediately remove the content in
          question. Any liability concerning this matter can only be assumed
          from the point in time at which the infringement becomes known to us.
        </p>
        <hr />
        <h2>Limitation of liability for external links</h2>
        <p>
          Our website contains links to the websites of third parties (“external
          links”). As the content of these websites is not under our control, we
          cannot assume any liability for such external content. In all cases,
          the provider of information of the linked websites is liable for the
          content and accuracy of the information provided. At the point in time
          when the links were placed, no infringements of the law were
          recognisable to us. As soon as an infringement of the law becomes
          known to us, we will immediately remove the link in question.
        </p>
        <hr />
        <h2>Copyright</h2>
        <p>
          The content and works published on this website are governed by the
          copyright laws of Germany. Any duplication, processing, distribution
          or any form of utilisation beyond the scope of copyright law shall
          require the prior written consent of the author or authors in
          question.
        </p>
        <hr />
        <h2>Data protection</h2>
        <p>
          A visit to our website can result in the storage on our server of
          information about the access (date, time, page accessed). This does
          not represent any analysis of personal data (e.g., name, address or
          e-mail address). If personal data are collected, this only occurs – to
          the extent possible – with the prior consent of the user of the
          website. Any forwarding of the data to third parties without the
          express consent of the user shall not take place.
        </p>
        <p>
          We would like to expressly point out that the transmission of data via
          the Internet (e.g., by e-mail) can offer security vulnerabilities. It
          is therefore impossible to safeguard the data completely against
          access by third parties. We cannot assume any liability for damages
          arising as a result of such security vulnerabilities.
        </p>
        <p>
          The use by third parties of all published contact details for the
          purpose of advertising is expressly excluded. We reserve the right to
          take legal steps in the case of the unsolicited sending of advertising
          information; e.g., by means of spam mail.
        </p>
      </div>
    </Fragment>
  );
};

export default Legal;
