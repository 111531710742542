import { Fragment } from 'react';
import classNames from 'classnames';

const TargetComponentFallback = (props) => <div {...props} />;

const FlexCenterOverflow = ({
  className,
  children,
  component: TargetComponent = TargetComponentFallback,
  center,
  ...props
}) => {
  return (
    <Fragment>
      {TargetComponent && (
        <TargetComponent
          className={classNames(
            className,
            'l-flex__item--overflow',
            'l-flex',
            'l-flex--column',
          )}
          {...props}
        >
          <div
            className={classNames(
              'l-flex__item--main',
              'l-flex',
              'l-flex--column',
              { 'l-flex--center-center': center },
            )}
          >
            {children}
          </div>
        </TargetComponent>
      )}
    </Fragment>
  );
};

export default FlexCenterOverflow;
