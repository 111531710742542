import { Fragment, useEffect, useState, useCallback } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useQuery } from '@redux-requests/react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Loader as RsLoader } from 'rsuite';
import axios from 'axios';

import Modal from 'app/components/Modal/Modal';
import FlexOverflow from 'app/components/FlexOverflow';
import LoaderError from 'app/components/LoaderError';
import Header from 'app/components/Header';
import { Provider as HeaderProvider } from 'app/components/Header/Context';
import configureStore from 'app/store/configure';
import Domain from 'app/domain';
import * as totoAuthActions from 'app/services/toto/auth/actions';

import 'app/styles/rsuite/index.less';
import './App.scss';

const axiosInstance = axios.create({
  baseURL: process.env?.REACT_APP_API_URL || '',
  xsrfCookieName: 'csrfToken',
  xsrfHeaderName: 'Csrf-Token',
});

const appName = process.env?.REACT_APP_NAME || '';

const Root = () => {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);

  const totoAuthFetchIdentityQuery = useQuery({
    type: totoAuthActions.FETCH_IDENTITY,
  });

  const fetchIdentity = useCallback(
    () =>
      dispatch(totoAuthActions.fetchIdentity()).finally(() =>
        setIsInitialized(true),
      ),
    [dispatch],
  );

  useEffect(() => {
    fetchIdentity();
  }, [fetchIdentity]);

  return (
    <Fragment>
      {!isInitialized || totoAuthFetchIdentityQuery.loading ? (
        <div className="l-page l-page--full-size l-flex l-flex--column l-flex--center-center">
          <RsLoader backdrop center size="md" speed="slow" />
        </div>
      ) : totoAuthFetchIdentityQuery.error ? (
        <LoaderError
          onRetry={fetchIdentity}
          message="App could not be initialized."
          retryable
          className="l-page l-page--full-size l-flex l-flex--column l-flex--center-center"
        ></LoaderError>
      ) : (
        <div className="l-page l-page--full-size l-flex l-flex--column">
          <Header />
          <FlexOverflow className="l-flex__item--main">
            <Switch>
              <Route path="/login" exact>
                <Domain.LogIn parentTitle={appName} />
              </Route>
              <Route path="/legal" exact>
                <Domain.Legal parentTitle={appName} />
              </Route>
              <Route path="/privacy" exact>
                <Domain.PrivacyPolicy parentTitle={appName} />
              </Route>
              <Route path="/">
                <Domain.Main parentTitle={appName} />
              </Route>
              {/* because path="/" is always reached we don't need a "otherwise" route here but in Domain.Main */}
            </Switch>
          </FlexOverflow>
        </div>
      )}
    </Fragment>
  );
};

const App = () => {
  return (
    <BrowserRouter>
      <ReduxProvider store={configureStore(axiosInstance)}>
        <HelmetProvider>
          <HeaderProvider>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{appName}</title>
            </Helmet>
            <Root />
            <Modal />
          </HeaderProvider>
        </HelmetProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default App;
