import { apiRootUrl } from './config';

const actionNamePrefix = 'TOTO/ACCOUNT';

export const FETCH = `${actionNamePrefix}/FETCH`;

export const fetch = ({ payload, meta = {} } = {}) => ({
  type: FETCH,
  request: {
    url: `${apiRootUrl}/${payload?.id}`,
    method: 'GET',
  },
  meta: {
    ...meta,
    getData: ({ repos = [], permissions = [] } = {}) => ({
      repositories: repos.map((repository) => ({
        ...repository,
        permission: permissions.find(
          (permission) => permission.repoId === repository.id,
        ),
      })),
    }),
  },
});
