import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';

import { assembleTitle } from 'app/services/title';

const NotFound = ({
  parentTitle = '',
  message = 'Page not found!',
  goBackTitle = 'main page',
  goBackHref = '/',
}) => {
  const title = useMemo(() => assembleTitle('404', parentTitle), [parentTitle]);

  return (
    <Fragment>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <p>{message}</p>
      <p>
        You can go back to the <NavLink to={goBackHref}>{goBackTitle}</NavLink>.
      </p>
    </Fragment>
  );
};

export default NotFound;
