import { apiRootUrl } from './config';

const actionNamePrefix = 'TOTO/OP';

export const SEARCH_USER_INPUT_DISPATCH_CONFIGS = `${actionNamePrefix}/SEARCH_USER_INPUT_DISPATCH_CONFIGS`;

export const searchUserInputDispatchConfigs = ({
  meta = {},
  payload = {},
} = {}) => ({
  type: SEARCH_USER_INPUT_DISPATCH_CONFIGS,
  request: {
    url: `${apiRootUrl}/${payload.repositoryId}/monitoring/config/search`,
    method: 'GET',
  },
  meta: {
    ...meta,
  },
});
