import { Fragment, useMemo, useCallback, useRef } from 'react';
import {
  FormGroup as RsFormGroup,
  ControlLabel as RsControlLabel,
  FormControl as RsFormControl,
  Button as RsButton,
  ButtonToolbar as RsButtonToolbar,
  Schema as RsSchema,
} from 'rsuite';
import { useDispatch } from 'react-redux';
import { useMutation } from '@redux-requests/react';

import * as totoAuthActions from 'app/services/toto/auth/actions';
import FormControlled from 'app/components/FormControlled';

const LogIn = () => {
  const dispatch = useDispatch();

  const rsFormRef = useRef();

  const totoAuthLogInQuery = useMutation({
    type: totoAuthActions.LOG_IN,
  });

  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (!rsFormRef.current?.check()) return;

      const rsFormValue = rsFormRef.current?.getFormValue();

      dispatch(
        totoAuthActions.logIn({
          payload: {
            username: rsFormValue.usernameOrEmail,
            password: rsFormValue.password,
            app: process.env.REACT_APP_ID,
          },
        }),
      );
    },
    [dispatch],
  );

  const rsFormModelDef = useMemo(
    () =>
      Object.fromEntries([
        [
          'usernameOrEmail',
          RsSchema.Types.StringType().isRequired('This field is required.'),
        ],
        [
          'password',
          RsSchema.Types.StringType().isRequired('This field is required.'),
        ],
      ]),
    [],
  );

  const formInitialValue = useMemo(
    () => ({
      usernameOrEmail: '',
      password: '',
    }),
    [],
  );

  return (
    <Fragment>
      <FormControlled
        rsFormModelDef={rsFormModelDef}
        rsFormRef={rsFormRef}
        initialValue={formInitialValue}
        style={{ width: 300 }}
      >
        <p className="rs-form-title">Please log in:</p>
        <RsFormGroup>
          <RsControlLabel>username or email</RsControlLabel>
          <RsFormControl name="usernameOrEmail" />
        </RsFormGroup>
        <RsFormGroup>
          <RsControlLabel>password</RsControlLabel>
          <RsFormControl type="password" name="password" />
        </RsFormGroup>
        <RsFormGroup>
          <RsButtonToolbar>
            <RsButton
              appearance="primary"
              type="submit"
              onClick={onFormSubmit}
              loading={totoAuthLogInQuery.loading}
            >
              log in
            </RsButton>
          </RsButtonToolbar>
        </RsFormGroup>
      </FormControlled>
    </Fragment>
  );
};

export default LogIn;
