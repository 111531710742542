import { useMemo, useCallback, useRef } from 'react';
import {
  FormGroup as RsFormGroup,
  ControlLabel as RsControlLabel,
  Schema as RsSchema,
  Input as RsInput,
  FormControl as RsFormControl,
  ButtonToolbar as RsButtonToolbar,
  Button as RsButton,
} from 'rsuite';
import classNames from 'classnames';

import FormControlled from 'app/components/FormControlled/FormControlled';

import styles from './CustomDispatchForm.module.scss';

const CustomDispatchForm = ({ onSubmit }) => {
  const rsFormRef = useRef();

  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (!rsFormRef.current?.check()) return;

      const rsFormValue = rsFormRef.current?.getFormValue();

      if (onSubmit instanceof Function) {
        onSubmit(rsFormValue);
      }
    },
    [onSubmit],
  );

  const formInitialValue = useMemo(
    () => ({
      payload: '',
    }),
    [],
  );

  const rsFormModelDef = useMemo(
    () => ({
      payload: RsSchema.Types.StringType().isRequired(
        'This field is required.',
      ),
    }),
    [],
  );

  return (
    <FormControlled
      initialValue={formInitialValue}
      rsFormRef={rsFormRef}
      rsFormModelDef={rsFormModelDef}
    >
      <RsFormGroup style={{ marginBottom: 10 }}>
        <RsControlLabel>Payload</RsControlLabel>
        <RsFormControl
          name="payload"
          accepter={RsInput}
          componentClass="textarea"
          placeholder="payload"
        />
      </RsFormGroup>
      <RsButtonToolbar className={classNames(styles.buttonToolbarRight)}>
        <RsButton
          appearance="primary"
          size="sm"
          type="submit"
          onClick={onFormSubmit}
        >
          dispatch
        </RsButton>
      </RsButtonToolbar>
    </FormControlled>
  );
};

export default CustomDispatchForm;
