import { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import {
  Header as RsHeader,
  Navbar as RsNavbar,
  Nav as RsNav,
  Icon as RsIcon,
  Avatar as RsAvatar,
  Dropdown as RsDropdown,
} from 'rsuite';
import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@redux-requests/react';
import classNames from 'classnames';

import { Context as HeaderContext } from './Context';
import DropdownItem from 'app/components/Dropdown/Item';
import Icon from 'app/components/Icon';
import * as totoAuthActions from 'app/services/toto/auth/actions';

import styles from './Header.module.scss';

const Header = () => {
  const dispatch = useDispatch();

  const totoAuthFetchIdentityQuery = useQuery({
    type: totoAuthActions.FETCH_IDENTITY,
  });

  const totoAuthLogOutQuery = useMutation({
    type: totoAuthActions.LOG_OUT,
  });

  const logOut = useCallback(() => {
    dispatch(totoAuthActions.logOut());
  }, [dispatch]);

  return (
    <HeaderContext.Consumer>
      {({ subNav, navbarHeaderExtension }) => (
        <RsHeader>
          <RsNavbar appearance="inverse">
            <RsNavbar.Header>
              <NavLink to="/">
                <RsIcon className="rs-navbar-brand logo">
                  <Icon name="Toto" />
                </RsIcon>
              </NavLink>
              {navbarHeaderExtension}
            </RsNavbar.Header>
            <RsNavbar.Body>
              <div className="l-flex l-flex--row">
                <RsNav className="l-flex__item--main">{subNav}</RsNav>
                <RsNav>
                  <RsDropdown
                    renderTitle={() => (
                      <RsAvatar
                        circle
                        className={classNames(styles.avatarSubtle)}
                      >
                        <RsIcon icon="home" />
                      </RsAvatar>
                    )}
                    placement="bottomEnd"
                  >
                    {!totoAuthFetchIdentityQuery.data?.id && (
                      <RsDropdown.Item componentClass={NavLink} to="/login">
                        log in
                      </RsDropdown.Item>
                    )}
                    {!totoAuthFetchIdentityQuery.data?.id && (
                      <RsDropdown.Item divider />
                    )}
                    <RsDropdown.Item componentClass={NavLink} to="/privacy">
                      privacy policy
                    </RsDropdown.Item>
                    <RsDropdown.Item componentClass={NavLink} to="/legal">
                      legal
                    </RsDropdown.Item>
                  </RsDropdown>
                </RsNav>
                {totoAuthFetchIdentityQuery.data?.id && (
                  <RsNav>
                    <RsDropdown
                      renderTitle={() => (
                        <RsAvatar
                          circle
                          src={
                            totoAuthFetchIdentityQuery.data.avatar &&
                            `/v2/account/${totoAuthFetchIdentityQuery.data.avatar}/avatar?v=thumb`
                          }
                        >
                          {totoAuthFetchIdentityQuery.data.username
                            .slice(0, 1)
                            .toUpperCase()}
                        </RsAvatar>
                      )}
                      placement="bottomEnd"
                    >
                      <RsDropdown.Item
                        panel
                        style={{ padding: 10, width: 160 }}
                      >
                        <p>
                          <strong>
                            {totoAuthFetchIdentityQuery.data.screenName}
                          </strong>
                        </p>
                        <p>@{totoAuthFetchIdentityQuery.data.username}</p>
                      </RsDropdown.Item>
                      <RsDropdown.Item divider />
                      <RsDropdown.Item componentClass={NavLink} to="/">
                        your repositories
                      </RsDropdown.Item>
                      <RsDropdown.Item divider />
                      <DropdownItem
                        onClick={logOut}
                        loading={totoAuthLogOutQuery.loading}
                      >
                        log out
                      </DropdownItem>
                    </RsDropdown>
                  </RsNav>
                )}
              </div>
            </RsNavbar.Body>
          </RsNavbar>
        </RsHeader>
      )}
    </HeaderContext.Consumer>
  );
};

export default Header;
