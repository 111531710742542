import { Fragment, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import DetailList from 'app/components/UserInput/DispatchConfig/DetailList';
import NotFound from 'app/components/NotFound';
import { assembleTitle } from 'app/services/title';

const DispatchConfig = ({
  parentTitle = '',
  repository,
  userInputDispatchConfigs = [],
}) => {
  const { userInputDispatchConfigName } = useParams();

  const userInputDispatchConfig = useMemo(
    () =>
      userInputDispatchConfigs.find(
        (userInputDispatchConfig) =>
          userInputDispatchConfig.name === userInputDispatchConfigName,
      ),
    [userInputDispatchConfigName, userInputDispatchConfigs],
  );

  const title = useMemo(
    () => assembleTitle(userInputDispatchConfigName, parentTitle),
    [parentTitle, userInputDispatchConfigName],
  );

  return (
    <Fragment>
      {userInputDispatchConfig?.id ? (
        <Fragment>
          <Helmet>
            <title>{title}</title>
          </Helmet>
          <DetailList
            key={userInputDispatchConfig.id}
            repository={repository}
            config={userInputDispatchConfig}
            localStorage
            localStorageKeyPrefix="nf-toto-operator-user-input-"
            withModal
          />
        </Fragment>
      ) : (
        <div className="l-flex__item--main l-flex l-flex--column l-flex--center-center">
          <NotFound
            parentTitle={parentTitle}
            message={
              <Fragment>
                Dispatch config <strong>{userInputDispatchConfigName}</strong>{' '}
                not found!
              </Fragment>
            }
          />
        </div>
      )}
    </Fragment>
  );
};

export default DispatchConfig;
