import { apiRootUrl } from './config';

const actionNamePrefix = 'TOTO/OP';

export const DISPATCH_CONFIG = `${actionNamePrefix}/DISPATCH_CONFIG`;
export const DISPATCH_DEFAULT_CONFIG = `${actionNamePrefix}/DISPATCH_DEFAULT_CONFIG`;
export const FETCH_CONFIG = `${actionNamePrefix}/FETCH_CONFIG`;

export const dispatchConfig = ({ meta = {}, payload = {} } = {}) => ({
  type: DISPATCH_CONFIG,
  request: {
    url: `${apiRootUrl}/${payload.repositoryId}/config/dispatch`,
    method: 'POST',
    data: {
      sessionId: payload.sessionId,
      configId: payload.configId,
      elementId: payload.elementId,
      accountId: payload.accountId,
    },
  },
  meta: {
    requestKey: `${payload.userInputId}:${payload.configId}`,
    ...meta,
  },
});

export const dispatchDefaultConfig = ({ meta = {}, payload = {} } = {}) => ({
  type: DISPATCH_DEFAULT_CONFIG,
  request: {
    url: `${apiRootUrl}/${payload.repositoryId}/config/dispatch/default`,
    method: 'POST',
    data: {
      configId: payload.configId,
      payload: payload.payload,
    },
  },
  meta: {
    requestKey: `${payload.payload}:${payload.configId}`,
    ...meta,
  },
});

export const fetchConfig = ({ meta = {}, payload = {} } = {}) => ({
  type: FETCH_CONFIG,
  request: {
    url: `${apiRootUrl}/${payload.repositoryId}/config/${payload.configId}`,
    method: 'GET',
    params: {
      since: payload.since,
      until: payload.until,
    },
  },
  meta: {
    ...meta,
    getData: (userInputs = []) =>
      userInputs.map((userInput) => ({
        ...userInput,
        processedAtToLocaleString: new Date(
          userInput.processedAt,
        ).toLocaleString('de-DE'),
      })),
  },
});
