import { Fragment } from 'react';

import { ReactComponent as IconToto } from 'assets/icons/Toto.svg';

// so for now we have to include all icons here
//
// if https://github.com/facebook/create-react-app/issues/5276 has been resolved we can switch back to something like
//
// =====
// = 1 =
// =====
//
// async function importIcon(name) {
//   return await import(`../../../assets/icons/${name}.svg`).catch((err) => {
//     console.error(
//       `An error occured while loading '../../../assets/icons/${name}.svg'!`,
//       err,
//     );
//   });
// }
//
// const Icon = ({ name }) => {
//   const [IconComponent, setIconComponent] = useState();
//
//   useEffect(async () => {
//     setIconComponent(await importIcon(name));
//   }, []);
//
//   return (
//     <React.Fragment>
//       {IconComponent && <IconComponent className={`icon icon-${name}`} />}
//     </React.Fragment>
//   );
// };
//
//
// =====
// = 2 =
// =====
//
// const Icon = ({ name, ...props }) => {
//   const ImportedIconRef = React.useRef(null);
//   const [loading, setLoading] = React.useState(false);

//   React.useEffect(() => {
//     setLoading(true);
//     const importIcon = async () => {
//       try {
//         ImportedIconRef.current = (
//           await import(`../../../assets/icons/${name}.svg`)
//         ).ReactComponent;
//       } catch (err) {
//         // Your own error handling logic, throwing error for the sake of
//         // simplicity
//         throw err;
//       } finally {
//         setLoading(false);
//       }
//     };
//     importIcon();
//   }, [name]);

//   if (!loading && ImportedIconRef.current) {
//     const { current: ImportedIcon } = ImportedIconRef;
//     return <ImportedIcon {...props} />;
//   }

//   return null;
// };

const icons = {
  Toto: IconToto,
};

const Icon = ({ name, ...props }) => {
  const IconItself = icons[name];

  return <Fragment>{IconItself && <IconItself {...props} />}</Fragment>;
};

export default Icon;
