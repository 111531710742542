import { handleActions } from 'redux-actions';

import * as modalActions from 'app/actions/modal/modal';

const initialState = {};

export default handleActions(
  {
    [modalActions.SHOW]: (state, action) => ({
      ...state,
      ...action.payload,
      isShown: true,
    }),
    [modalActions.HIDE]: (state) => ({
      ...state,
      isShown: false,
    }),
    [modalActions.WAIT]: (state, action) => ({
      ...state,
      isWaiting: action.payload,
    }),
    [modalActions.DESTROY]: () => ({
      ...initialState,
    }),
  },
  initialState,
);
