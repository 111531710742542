import {
  Fragment,
  useCallback,
  useState,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { addDays } from 'date-fns';
import {
  List as RsList,
  Message as RsMessage,
  Loader as RsLoader,
  Schema as RsSchema,
  FormGroup as RsFormGroup,
  ControlLabel as RsControlLabel,
  FormControl as RsFormControl,
  Button as RsButton,
  ButtonToolbar as RsButtonToolbar,
  InputNumber as RsInputNumber,
  DatePicker as RsDatePicker,
  Content as RsContent,
  Dropdown as RsDropdown,
} from 'rsuite';
import classNames from 'classnames';
import _ from 'lodash';
import { abortRequests } from '@redux-requests/core';
import { useDispatch } from 'react-redux';
import { useLocalStorage } from 'react-recipes';

import CustomDispatchForm from 'app/components/CustomDispatchForm';
import CustomDispatchModalForm from 'app/components/CustomDispatchModalForm';
import DetailListItem from './Item';
import * as apiUtils from 'app/services/toto/api';
import * as totoOpActions from 'app/services/toto/op/actions';
import FormControlled from 'app/components/FormControlled';
import LoaderError from 'app/components/LoaderError';
import * as modalActions from 'app/actions/modal/modal';

import styles from './DetailList.module.scss';

const DetailListPreForm = ({ onSubmit, pollingInterval }) => {
  const rsFormRef = useRef();

  const onFormSubmit = useCallback(
    (event) => {
      event.preventDefault();

      if (!rsFormRef.current?.check()) return;

      const rsFormValue = rsFormRef.current?.getFormValue();

      if (onSubmit instanceof Function) {
        onSubmit(rsFormValue);
      }
    },
    [onSubmit],
  );

  const rsFormModelDef = useMemo(
    () =>
      Object.fromEntries([
        ['since', RsSchema.Types.DateType()],
        [
          'pollingInterval',
          RsSchema.Types.NumberType().isRequired('This field is required.'),
        ],
      ]),
    [],
  );

  const formInitialValue = useMemo(
    () => ({
      since: addDays(new Date(), -1.5),
      pollingInterval: pollingInterval,
    }),
    [pollingInterval],
  );

  return (
    <FormControlled
      rsFormModelDef={rsFormModelDef}
      rsFormRef={rsFormRef}
      initialValue={formInitialValue}
    >
      <p className="rs-form-title">Please define:</p>
      <RsFormGroup>
        <RsControlLabel>since</RsControlLabel>
        <RsFormControl
          name="since"
          accepter={RsDatePicker}
          format="DD.MM.YYYY HH:mm:ss"
          isoWeek
          showWeekNumbers
          block
          oneTap
          autoFocus
          placeholder="Select date"
        />
      </RsFormGroup>
      <RsFormGroup>
        <RsControlLabel>polling interval</RsControlLabel>
        <RsFormControl
          accepter={RsInputNumber}
          name="pollingInterval"
          postfix="seconds"
          min={1}
        />
      </RsFormGroup>
      <RsFormGroup>
        <RsButtonToolbar>
          <RsButton appearance="primary" type="submit" onClick={onFormSubmit}>
            start
          </RsButton>
        </RsButtonToolbar>
      </RsFormGroup>
    </FormControlled>
  );
};

const DetailList = ({
  repository,
  config,
  localStorage = false,
  localStorageKeyPrefix = '',
  stoppable = false,
  withModal = false,
}) => {
  const setTimoutRef = useRef();
  const dispatch = useDispatch();
  const [userInputs, setUserInputs] = useLocalStorage(
    `${localStorageKeyPrefix}user-inputs`,
  );
  const userInputsRef = useRef(userInputs);

  const pollingIntervalRef = useRef(5);
  const sinceTsRef = useRef();
  const sinceRef = useRef();

  const isStartedRef = useRef(false);
  const [isStarted, setIsStarted] = useState(false);
  const [error, setError] = useState();
  const [isInitiallyLoaded, setIsInitiallyLoaded] = useState(false);

  const [listLeftData, setListLeftData] = useState([]);
  const [listRightData, setListRightData] = useState([]);

  const onReject = useCallback((userInput) => {
    setListLeftData((prevData) =>
      prevData.filter((d) => d.id !== userInput.id),
    );

    setListRightData((prevData) =>
      _.orderBy(
        [...prevData, { ...userInput, __status: 'rejected' }],
        'processedAt',
        'desc',
      ),
    );

    if (localStorage) {
      if (!userInputsRef.current) {
        userInputsRef.current = {};
      }

      userInputsRef.current.rejected = [
        ...new Set(userInputsRef.current.rejected).add(userInput.id),
      ];

      setUserInputs(userInputsRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDispatch = useCallback((userInput) => {
    setListLeftData((prevData) =>
      prevData.filter((d) => d.id !== userInput.id),
    );

    setListRightData((prevData) =>
      _.orderBy(
        [...prevData, { ...userInput, __status: 'confirmed' }],
        'processedAt',
        'desc',
      ),
    );

    if (localStorage) {
      if (!userInputsRef.current) {
        userInputsRef.current = {};
      }

      userInputsRef.current.confirmed = [
        ...new Set(userInputsRef.current.confirmed).add(userInput.id),
      ];

      setUserInputs(userInputsRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const load = useCallback(
    (hardReload = false) => {
      clearTimeout(setTimoutRef.current);

      dispatch(
        totoOpActions.fetchConfig({
          payload: {
            repositoryId: repository.id,
            configId: config.id,
            since: sinceTsRef.current,
          },
        }),
      ).then(({ error, data }) => {
        setError(error);
        setIsInitiallyLoaded(true);

        if (data?.length > 0) {
          let newLeftData = data;
          let newRightData = [];

          if (
            userInputs?.rejected?.length > 0 ||
            userInputs?.confirmed?.length > 0
          ) {
            newLeftData = [];

            data.forEach((d) => {
              if (userInputsRef.current.rejected?.includes(d.id)) {
                newRightData.push({ ...d, __status: 'rejected' });
              } else if (userInputsRef.current.confirmed?.includes(d.id)) {
                newRightData.push({ ...d, __status: 'confirmed' });
              } else {
                newLeftData.push(d);
              }
            });
          }

          setListLeftData((prevData) => {
            const newData = _.uniqBy(
              _.orderBy(
                [...newLeftData, ...(hardReload ? [] : prevData)],
                'processedAt',
                'asc',
              ),
              'id',
            );

            if (
              newData[newData.length - 1]?.processedAt > sinceTsRef.current ||
              !sinceTsRef.current
            ) {
              sinceTsRef.current = newData[newData.length - 1]?.processedAt + 1;
            }

            return newData;
          });

          setListRightData((prevData) => {
            const newData = _.uniqBy(
              _.orderBy(
                [...newRightData, ...(hardReload ? [] : prevData)],
                'processedAt',
                'desc',
              ),
              'id',
            );

            if (
              newData[0]?.processedAt > sinceTsRef.current ||
              !sinceTsRef.current
            ) {
              sinceTsRef.current = newData[0]?.processedAt + 1;
            }

            return newData;
          });
        } else {
          if (hardReload) {
            setListLeftData([]);
            setListRightData([]);
          }
        }

        clearTimeout(setTimoutRef.current);

        if (isStartedRef.current) {
          setTimoutRef.current = setTimeout(
            load,
            pollingIntervalRef.current * 1000,
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.id, dispatch, repository.id],
  );

  const start = useCallback(
    ({ pollingInterval, since }) => {
      pollingIntervalRef.current = pollingInterval;
      sinceTsRef.current =
        since instanceof Date && !isNaN(since.getTime())
          ? since.getTime()
          : undefined;
      sinceRef.current = since;

      isStartedRef.current = true;
      setIsStarted(true);
      load();
    },
    [load],
  );

  const dispatchDefaultConfig = useCallback(
    (payload, name, isCustom) => {
      dispatch(
        totoOpActions.dispatchDefaultConfig({
          payload: {
            repositoryId: repository.id,
            configId: config.id,
            payload: payload,
          },
          meta: {
            defaultName: name,
            isCustom: isCustom,
          },
        }),
      );
    },
    [config.id, dispatch, repository.id],
  );

  const openCustomDispatchFormModal = useCallback(() => {
    dispatch(
      modalActions.show({
        payload: {
          type: 'form',
          content: {
            form: {
              component: CustomDispatchModalForm,
              initialValue: {
                payload:
                  window.localStorage.getItem(
                    `${localStorageKeyPrefix}custom-payload`,
                  ) || '',
              },
              onFormChange: (formValue) => {
                window.localStorage.setItem(
                  `${localStorageKeyPrefix}custom-payload`,
                  formValue?.payload || '',
                );
              },
            },
            title: `Dispatch custom payload`,
            buttonPrimaryCaption: 'dispatch',
          },
          actions: {
            submit: {
              creator: (formValue) =>
                totoOpActions.dispatchDefaultConfig({
                  payload: {
                    repositoryId: repository.id,
                    configId: config.id,
                    payload: formValue?.payload,
                  },
                  meta: {
                    isCustom: true,
                  },
                }),
            },
            cancel: {
              creator: () =>
                abortRequests([totoOpActions.DISPATCH_DEFAULT_CONFIG]),
            },
          },
        },
      }),
    );
  }, [config.id, dispatch, localStorageKeyPrefix, repository.id]);

  useEffect(
    () => () => {
      clearTimeout(setTimoutRef.current);
      dispatch(abortRequests([totoOpActions.FETCH_CONFIG]));
      dispatch(abortRequests([totoOpActions.DISPATCH_CONFIG]));
      dispatch(abortRequests([totoOpActions.DISPATCH_DEFAULT_CONFIG]));
    },
    [dispatch],
  );

  return (
    <Fragment>
      {!isStarted ? (
        <RsContent className="l-flex l-flex--column l-flex--center-center l-flex__item--main">
          <DetailListPreForm
            onSubmit={start}
            pollingInterval={pollingIntervalRef.current}
          />
        </RsContent>
      ) : (
        <Fragment>
          {!isInitiallyLoaded ? (
            <RsContent
              className={classNames(
                'l-flex',
                'l-flex--column',
                'l-flex--center-center',
              )}
            >
              <RsLoader size="md" speed="slow" />
            </RsContent>
          ) : (
            <RsContent className="l-flex l-flex--column">
              <div
                className={classNames(
                  styles.actionBar,
                  'l-flex',
                  'l-flex--row',
                )}
              >
                <RsFormGroup
                  className="l-flex__item--main"
                  style={{ maxWidth: 300 }}
                >
                  <RsControlLabel>since</RsControlLabel>
                  <RsDatePicker
                    format="DD.MM.YYYY HH:mm:ss"
                    isoWeek
                    showWeekNumbers
                    block
                    oneTap
                    autoFocus
                    placeholder="Select date"
                    defaultValue={sinceRef.current}
                    size="sm"
                    style={{ width: '100%' }}
                    onChange={(since) => {
                      sinceTsRef.current =
                        since instanceof Date && !isNaN(since.getTime())
                          ? since.getTime()
                          : undefined;

                      load(true);
                    }}
                  />
                </RsFormGroup>
                <RsFormGroup
                  className="l-flex__item--main"
                  style={{ maxWidth: 300 }}
                >
                  <RsControlLabel>polling interval</RsControlLabel>
                  <RsInputNumber
                    defaultValue={pollingIntervalRef.current}
                    onChange={(value) => (pollingIntervalRef.current = value)}
                    min={1}
                    postfix="seconds"
                    size="sm"
                    style={{ width: '100%' }}
                  />
                </RsFormGroup>
                <RsFormGroup>
                  <RsButton
                    appearance="primary"
                    onClick={() => {
                      load();
                    }}
                    size="sm"
                  >
                    refetch
                  </RsButton>
                </RsFormGroup>
                {withModal && (
                  <RsFormGroup>
                    <RsDropdown
                      title="dispatch"
                      renderTitle={(children) => {
                        return (
                          <RsButton size="sm" appearance="primary">
                            {children}
                          </RsButton>
                        );
                      }}
                      placement="bottomEnd"
                    >
                      <RsDropdown.Item
                        onSelect={openCustomDispatchFormModal}
                        className={classNames(styles.dropdownItem)}
                      >
                        custom
                      </RsDropdown.Item>
                      {config.defaults?.length > 0 && (
                        <Fragment>
                          {' '}
                          <RsDropdown.Item
                            divider
                            className={classNames(styles.dropdownItem)}
                          />
                          <RsDropdown.Item
                            panel
                            style={{ padding: '6px 8px', opacity: 0.5 }}
                            className={classNames(styles.dropdownItem)}
                          >
                            <small>
                              <strong>defaults</strong>
                            </small>
                          </RsDropdown.Item>
                        </Fragment>
                      )}
                      {config.defaults?.map((_default, index) => (
                        <RsDropdown.Item
                          key={`${_default.name}:${index}`}
                          onSelect={() =>
                            dispatchDefaultConfig(
                              _default.payload,
                              _default.name,
                            )
                          }
                          className={classNames(styles.dropdownItem)}
                        >
                          {_default.name}
                        </RsDropdown.Item>
                      ))}
                    </RsDropdown>
                  </RsFormGroup>
                )}
                {stoppable && (
                  <RsFormGroup>
                    <RsButton
                      appearance="link"
                      color="red"
                      onClick={() => {
                        clearTimeout(setTimoutRef.current);
                        setIsInitiallyLoaded(false);
                        setIsStarted(false);
                        dispatch(abortRequests([totoOpActions.FETCH_CONFIG]));
                        dispatch(
                          abortRequests([totoOpActions.DISPATCH_CONFIG]),
                        );
                        dispatch(
                          abortRequests([
                            totoOpActions.DISPATCH_DEFAULT_CONFIG,
                          ]),
                        );
                        isStartedRef.current = false;
                        setListLeftData([]);
                        setListRightData([]);
                      }}
                      size="sm"
                    >
                      stop
                    </RsButton>
                  </RsFormGroup>
                )}
              </div>
              {!withModal && (
                <>
                  {config.defaults?.length > 0 && (
                    <div
                      className={classNames(
                        styles.actionBar,
                        'l-flex',
                        'l-flex--row',
                      )}
                    >
                      {config.defaults?.map((_default, index) => (
                        <RsButton
                          key={`${_default.name}:${index}`}
                          onClick={() =>
                            dispatchDefaultConfig(
                              _default.payload,
                              _default.name,
                            )
                          }
                          size="sm"
                          appearance="primary"
                        >
                          {_default.name}
                        </RsButton>
                      ))}
                    </div>
                  )}
                  <div className={classNames(styles.actionBarSingle)}>
                    <CustomDispatchForm
                      onSubmit={({ payload }) => {
                        dispatchDefaultConfig(payload, undefined, true);
                      }}
                    />
                  </div>
                </>
              )}
              {error && (
                <RsMessage
                  style={{ marginBottom: 10 }}
                  type="error"
                  showIcon
                  description={
                    <Fragment>
                      Loading new data failed! Will retry automatically.
                      <br />
                      <small>{apiUtils.augmentError(error)?.description}</small>
                    </Fragment>
                  }
                />
              )}
              {listLeftData.length + listRightData.length > 0 ? (
                <div className={classNames(styles.listGrid)}>
                  <RsList hover className={classNames(styles.list)}>
                    {listLeftData.map((userInput, index) => (
                      <DetailListItem
                        key={userInput.id}
                        userInput={userInput}
                        index={index}
                        repository={repository}
                        config={config}
                        hasActions
                        onReject={onReject}
                        onDispatch={onDispatch}
                      />
                    ))}
                  </RsList>
                  <RsList className={classNames(styles.list)}>
                    {listRightData.map((userInput, index) => (
                      <DetailListItem
                        key={userInput.id}
                        userInput={userInput}
                        index={index}
                        repository={repository}
                        config={config}
                        status={userInput.__status}
                      />
                    ))}
                  </RsList>
                </div>
              ) : (
                <LoaderError
                  message="No data available!"
                  className="l-flex l-flex--column l-flex--center-center l-flex__item--main"
                />
              )}
            </RsContent>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DetailList;
