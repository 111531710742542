import { all, put, take, race, takeLatest } from 'redux-saga/effects';
import { success, error } from '@redux-requests/core';

import * as modalActions from 'app/actions/modal/modal';

function* requestFlowSaga(requestAction) {
  yield put(modalActions.wait({ payload: true }));
  yield put(requestAction);

  const result = yield race({
    success: take(success(requestAction.type)),
    error: take(error(requestAction.type)),
    cancel: take(modalActions.CANCEL),
  });

  yield put(modalActions.wait({ payload: false }));

  if (result.success) {
    yield put(modalActions.hide());
  }

  return result;
}

export function* confirmSaga(action) {
  yield requestFlowSaga(action.meta.__data__.action);
}

export function* submitSaga(action) {
  yield requestFlowSaga(action.meta.__data__.action);
}

export default function* saga() {
  yield all([
    takeLatest(modalActions.CONFIRM, confirmSaga),
    takeLatest(modalActions.SUBMIT, submitSaga),
  ]);
}
