import React, { useCallback, useRef, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Modal as RsModal,
  Button as RsButton,
  ButtonToolbar as RsButtonToolbar,
} from 'rsuite';
import classNames from 'classnames';

import Backdrop from 'app/components/Backdrop/Backdrop';
import * as modalActions from 'app/actions/modal/modal';

const ModalForm = ({ content, onClosed, actions }) => {
  const dispatch = useDispatch();

  // we need to use useRef here to get
  // access from within useMemo
  const formRefRef = useRef();
  const formValueRef = useRef();

  const [isPopoverOpened, setIsPopoverOpened] = useState();
  const [isPickerOpened, setIsPickerOpened] = useState();

  const [formId, setFormId] = useState();

  const isWaiting = useSelector(({ modal }) => modal.isWaiting);
  const isShown = useSelector(({ modal }) => modal.isShown);

  const popover = useMemo(
    () => ({
      onOpen: () => setIsPopoverOpened(true),
      onClose: () => setIsPopoverOpened(false),
    }),
    [],
  );

  const picker = useMemo(
    () => ({
      onOpen: () => setIsPickerOpened(true),
      onClosed: () => setIsPickerOpened(false),
    }),
    [],
  );

  const onCancel = useCallback(() => {
    dispatch(modalActions.hide());
    dispatch(modalActions.cancel());

    if (actions?.cancel.creator) {
      dispatch(actions.cancel.creator());
    }
  }, [actions, dispatch]);

  const Form = useMemo(() => content.form.component, [content.form.component]);

  const formOnReady = useCallback(({ ref }) => {
    formRefRef.current = ref;
    formValueRef.current = ref.current.getFormValue();

    setFormId(formRefRef.current.current.props.id);
  }, []);

  const formOnChange = useCallback(
    (value) => {
      formValueRef.current = value;

      if (content.form.onFormChange instanceof Function) {
        content.form.onFormChange(value);
      }
    },
    [content.form],
  );

  const submitForm = useCallback(() => {
    if (!formRefRef.current.current.check()) return;

    const formValue =
      content.form.transformFormOutput instanceof Function
        ? content.form.transformFormOutput(formValueRef.current)
        : formValueRef.current;

    dispatch(
      modalActions.submit({
        meta: { __data__: { action: actions.submit.creator(formValue) } },
      }),
    );
  }, [actions.submit, content.form, dispatch]);

  return (
    <RsModal
      backdrop
      backdropClassName={classNames({
        'no-pointer-events': isPopoverOpened || isPickerOpened,
      })}
      keyboard={!isPopoverOpened && !isPickerOpened}
      show={isShown}
      onHide={onCancel}
      onExited={onClosed}
      overflow={false}
      size="xs"
      className="modal-form"
    >
      <Backdrop
        isShown={isPopoverOpened}
        className="rs-modal-content-backdrop"
      />
      <RsModal.Header>
        {content.title && <RsModal.Title>{content.title}</RsModal.Title>}
      </RsModal.Header>
      <RsModal.Body>
        <Form
          {...content.form.componentProps}
          initialValue={content.form.initialValue}
          actions={content.form.actions}
          onChange={formOnChange}
          onReady={formOnReady}
          picker={picker}
          popover={popover}
        />
      </RsModal.Body>
      <RsModal.Footer>
        <RsButtonToolbar>
          <RsButton onClick={onCancel} appearance="subtle" disabled={!isShown}>
            cancel
          </RsButton>
          <RsButton
            loading={isWaiting}
            onClick={submitForm}
            appearance="primary"
            disabled={!isShown}
            type="submit"
            form={formId}
          >
            {content.buttonPrimaryCaption}
          </RsButton>
        </RsButtonToolbar>
      </RsModal.Footer>
    </RsModal>
  );
};

export default ModalForm;
