import { all, takeLatest, call } from 'redux-saga/effects';
import { Fragment } from 'react';
import { success, error } from '@redux-requests/core';

import * as rsNotificationUtils from 'app/services/rsuite/notification';
import * as apiUtils from 'app/services/toto/api';
import * as actions from './actions';

// function* dispatchConfigSuccessSaga() {
//   yield call(rsNotificationUtils.show, {
//     title: <Fragment>Dispatched!</Fragment>,
//     type: 'success',
//   });
// }
function* dispatchConfigErrorSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: <Fragment>Dispatch failed!</Fragment>,
    description: apiUtils.augmentError(action?.error)?.description,
    type: 'error',
  });
}

function* dispatchDefaultConfigSuccessSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: (
      <Fragment>
        {action.meta.isCustom ? (
          <Fragment>Custom payload dispatched!</Fragment>
        ) : (
          <Fragment>Default "{action.meta.defaultName}" dispatched!</Fragment>
        )}
      </Fragment>
    ),
    type: 'success',
    duration: 1000,
  });
}
function* dispatchDefaultConfigErrorSaga(action) {
  yield call(rsNotificationUtils.show, {
    title: (
      <Fragment>
        {action.meta.isCustom ? (
          <Fragment>Dispatching custom payload failed!</Fragment>
        ) : (
          <Fragment>
            Dispatching default "{action.meta.defaultName}" failed!
          </Fragment>
        )}
      </Fragment>
    ),
    description: apiUtils.augmentError(action?.error)?.description,
    type: 'error',
  });
}

// function* fetchConfigErrorSaga(action) {
//   yield call(rsNotificationUtils.show, {
//     title: (
//       <Fragment>Dispatch config could not be loaded!</Fragment>
//     ),
//     description: apiUtils.augmentError(action?.error)?.description,
//     type: 'error',
//   });
// }

export default function* saga() {
  yield all([
    // takeLatest(success(actions.DISPATCH_CONFIG), dispatchConfigSuccessSaga),
    takeLatest(error(actions.DISPATCH_CONFIG), dispatchConfigErrorSaga),
    takeLatest(
      success(actions.DISPATCH_DEFAULT_CONFIG),
      dispatchDefaultConfigSuccessSaga,
    ),
    takeLatest(
      error(actions.DISPATCH_DEFAULT_CONFIG),
      dispatchDefaultConfigErrorSaga,
    ),
    // takeLatest(error(actions.FETCH_CONFIG), fetchConfigErrorSaga),
  ]);
}
